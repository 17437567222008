exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-2-js": () => import("./../../../src/pages/about-2.js" /* webpackChunkName: "component---src-pages-about-2-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-blog-details-js": () => import("./../../../src/pages/blog/blog-details.js" /* webpackChunkName: "component---src-pages-blog-blog-details-js" */),
  "component---src-pages-blog-blog-right-sidebar-js": () => import("./../../../src/pages/blog/blog-right-sidebar.js" /* webpackChunkName: "component---src-pages-blog-blog-right-sidebar-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-10-js": () => import("./../../../src/pages/index-10.js" /* webpackChunkName: "component---src-pages-index-10-js" */),
  "component---src-pages-index-11-js": () => import("./../../../src/pages/index-11.js" /* webpackChunkName: "component---src-pages-index-11-js" */),
  "component---src-pages-index-12-js": () => import("./../../../src/pages/index-12.js" /* webpackChunkName: "component---src-pages-index-12-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-4-js": () => import("./../../../src/pages/index-4.js" /* webpackChunkName: "component---src-pages-index-4-js" */),
  "component---src-pages-index-5-js": () => import("./../../../src/pages/index-5.js" /* webpackChunkName: "component---src-pages-index-5-js" */),
  "component---src-pages-index-6-js": () => import("./../../../src/pages/index-6.js" /* webpackChunkName: "component---src-pages-index-6-js" */),
  "component---src-pages-index-7-js": () => import("./../../../src/pages/index-7.js" /* webpackChunkName: "component---src-pages-index-7-js" */),
  "component---src-pages-index-8-js": () => import("./../../../src/pages/index-8.js" /* webpackChunkName: "component---src-pages-index-8-js" */),
  "component---src-pages-index-9-js": () => import("./../../../src/pages/index-9.js" /* webpackChunkName: "component---src-pages-index-9-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-service-details-js": () => import("./../../../src/pages/services/service-details.js" /* webpackChunkName: "component---src-pages-services-service-details-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

